body {
  background-color: black;
  /* Optionally, you can set other styles here */
  font-family: 'Courier New', Courier, monospace;

}


.App {
  text-align: left;
  background-color: black;
  color: white;
  height: 100vh;
}

.App-header {
  background-color: black;
  padding: 20px;
}

.Title {
  font-size: 3rem;
  margin: 0;
}

.Subtitle {
  font-size: 1.5rem;
  margin-top: 5px;
  margin-bottom: 30px;
}

.Main-content {
  padding: 20px;
}

.Description {
  font-size: 1rem;
  line-height: 1.5;
}